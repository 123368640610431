import dayjs from 'dayjs'
import { GetShiftsClass, GetShiftsClassCount } from '@/api/shiftsClass'
import {
  GetShiftsSchedule,
  GetShiftsScheduleCount,
  UpdateShiftsSchedule,
} from '@/api/shiftSchedule'
import { mapGetters } from 'vuex'
import { timesConvert } from '@/utils/helper'

export default {
  data: () => ({
    calendarRange: {
      start: null,
      end: null,
    },
    shopShiftsClassList: [], // 所有班別
    shopScheduleList: [], // 所有班表
    loading: false,
  }),
  computed: {
    ...mapGetters(['shop']),
    // 處理搜尋排班班表區間資料
    calendarRangeString () {
      const format = 'YYYY-MM-DD'
      const { start, end } = this.calendarRange
      let dayStart, dayEnd
      if (start && end) {
        dayStart = start.format(format)
        dayEnd = end.format(format)
      } else {
        dayStart = dayjs()
          .startOf('month')
          .format(format)
        dayEnd = dayjs()
          .endOf('month')
          .format(format)
      }
      return { dayStart, dayEnd }
    },
  },
  methods: {
    getCalendarDate (calendarApi) {
      calendarApi.getDate()
      const date = dayjs(calendarApi.getDate())
      return {
        start: date.startOf('month').startOf('week'),
        end: date.endOf('month').endOf('week'),
      }
    },

    // => 取得當前店家所有班別總數
    async getShopShiftsClassCount () {
      const res = await GetShiftsClassCount({ shopId: this.shop })
      return res.count
    },

    // => 取得當前店家所有班別
    async getShopShiftsClass () {
      const max = await this.getShopShiftsClassCount()

      let start = 0
      const limit = 100
      const data = {
        shopId: this.shop,
        start,
        limit,
      }
      const res = await GetShiftsClass(data)
      this.shopShiftsClassList = res

      while (this.shopShiftsClassList.length < max) {
        start += 100
        const res = await GetShiftsClass(data)
        this.shopShiftsClassList = this.shopShiftsClassList.concat(res)
      }
    },

    // => 取得當前店家所有班表總數
    async getShiftsScheduleCount () {
      const res = await GetShiftsScheduleCount({
        shopId: this.shop,
        ...this.calendarRangeString,
      })
        .then((res) => res.data)
        .catch(() => {
          this.$message.error('取得班表失敗')
          return { count: 0 }
        })
      return res.count
    },

    // => 取得排班表
    async getShiftsSchedule () {
      this.loading = true
      this.shopScheduleList = []
      const max = await this.getShiftsScheduleCount()
      try {
        let startIndex = 0
        const limit = 20
        let data = {
          shopId: this.shop,
          start: startIndex,
          limit,
          ...this.calendarRangeString,
        }
        while (this.shopScheduleList.length < max) {
          data = {
            shopId: this.shop,
            start: startIndex,
            limit,
            ...this.calendarRangeString,
          }
          const res = await GetShiftsSchedule(data)
          startIndex += limit
          // 店家班別
          this.shopScheduleList = this.shopScheduleList.concat(res.data)
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error('取得班表失敗')
      }
    },

    // => 更新班表
    async updateShiftsSchedule (newSchedule) {
      try {
        await UpdateShiftsSchedule({
          shopId: this.shop,
          data: newSchedule,
        })
        this.$message.success('更新排班班表成功 !')
      } catch (error) {
        this.$message.error(error)
      }
    },

    // => 創建月曆事件
    createCalendarEvent ({
      title,
      day,
      timeStart,
      timeEnd,
      mode,
      props,
      color,
    }) {
      const start = timesConvert(timeStart)
      let end = timesConvert(timeEnd)
      if (end === '24:00') end = '23:59:59'
      const data = {
        title,
        start: `${day} ${start}`,
        end: `${day} ${end}`,
        allDay: false,
        extendedProps: props,
        backgroundColor: color,
      }
      return data
    },

  },
}
