var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shifts-calendar-setting"},[_c('PageTitle',{attrs:{"title":"排班行事曆","btn":"編輯排班班表","btn2":"關閉時段","cyBtn1":"edit-shift-schedule-btn"},on:{"btnClick":function($event){return _vm.$router.push({
      name: 'ShiftsCalendar'
    })},"btn2Click":function($event){_vm.togglePeriodDialog = true}}}),_c('FiltersContainer',[_c('ServicesUnitSelect',{attrs:{"model":_vm.search.unit},on:{"update:model":function($event){return _vm.$set(_vm.search, "unit", $event)},"change":function($event){return _vm.showAllSchedule()}}})],1),_c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"flex-1"},[(_vm.show)?_c('FullCalendar',{ref:"showCalendar",staticClass:"h-full block",attrs:{"options":_vm.showCalendar}}):_vm._e(),_c('div',{staticStyle:{"height":"800px"}})],1),_c('el-dialog',{key:"calendar-edit",attrs:{"title":"編輯排班班表","visible":_vm.editDialog,"width":"700px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.editDialog=$event},"close":function($event){;(_vm.selectServiceUnit = null),
        (_vm.editCalendar.events = []),
        (_vm.editMode = false),
        (_vm.editNewSchedule = [])}}},[_c('el-form',{attrs:{"label-position":"left","label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"選擇服務車次"}},[_c('div',{staticClass:"flex"},[_c('el-select',{attrs:{"placeholder":"請選擇","value-key":"id","no-data-text":"無數據"},on:{"change":_vm.changeServiceUnit},model:{value:(_vm.selectServiceUnit),callback:function ($$v) {_vm.selectServiceUnit=$$v},expression:"selectServiceUnit"}},_vm._l((_vm.serviceUnitList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item}})}),1),(!_vm.editMode && _vm.selectServiceUnit)?_c('el-button',{staticClass:"ml-2 p-2",attrs:{"plain":""},on:{"click":function($event){_vm.editMode = true}}},[_vm._v(" 點我編輯 ")]):_vm._e()],1)]),(_vm.editMode)?_c('el-form-item',{attrs:{"label":"選擇排班班別"}},[_c('el-select',{attrs:{"placeholder":"請選擇","value-key":"id","no-data-text":"無數據"},model:{value:(_vm.editScheduleClass),callback:function ($$v) {_vm.editScheduleClass=$$v},expression:"editScheduleClass"}},_vm._l((_vm.shopShiftsClassList),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item}})}),1)],1):_vm._e()],1),(_vm.editMode)?_c('section',{staticClass:"flex-end"},[_c('el-button',{attrs:{"plain":""},on:{"click":function($event){;(_vm.editMode = false),
            _vm.changeServiceUnit(_vm.selectServiceUnit),
            (_vm.editNewSchedule = [])}}},[_vm._v(" 取 消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveEditSchedule()}}},[_vm._v("儲存")])],1):_vm._e(),(_vm.editDialog)?_c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"test"},[_c('FullCalendar',{ref:"calendar",attrs:{"options":_vm.editCalendar}})],1):_vm._e()],1),_c('el-dialog',{key:"event-edit",attrs:{"title":"編輯排班班別","visible":_vm.updateDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.updateDialog=$event}}},[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"日期 ："}},[(_vm.selectSchedule)?_c('div',[_vm._v(_vm._s(_vm.selectSchedule.day))]):_vm._e()]),_c('el-form-item',{attrs:{"label":"服務車次 ："}},[(_vm.selectSchedule)?_c('div',[_vm._v(" "+_vm._s(_vm.selectSchedule.AppointmentUnit.name)+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"排班班別 ："}},[_c('el-select',{model:{value:(_vm.selectScheduleClass),callback:function ($$v) {_vm.selectScheduleClass=$$v},expression:"selectScheduleClass"}},_vm._l((_vm.shopShiftsClassList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('div',{staticClass:"flex-end"},[_c('el-button',{attrs:{"plain":""},on:{"click":function($event){_vm.updateDialog = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateScheduleCalss()}}},[_vm._v(" 儲存 ")])],1)],1)],1),(_vm.togglePeriodDialog)?_c('TogglePeriodDialog',{attrs:{"title":"編輯關閉時段","width":"440px"},on:{"close":function($event){_vm.togglePeriodDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }