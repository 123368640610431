<template>
  <div class="shifts-calendar-setting">
    <PageTitle
      title="排班行事曆"
      btn="編輯排班班表"
      btn2="關閉時段"
      cyBtn1="edit-shift-schedule-btn"
      @btnClick="$router.push({
        name: 'ShiftsCalendar'
      })"
      @btn2Click="togglePeriodDialog = true"
    />

    <FiltersContainer>
      <ServicesUnitSelect :model.sync="search.unit" @change="showAllSchedule()" />
      <!-- <el-button type="primary" class="ml-3" @click="handleEditDialog"
        >編輯排班班表</el-button> -->
      <!-- <router-link :to="{name: 'ShiftsCalendar'}">
        <el-button
          type="primary"
          class="ml-3"
        >
          編輯排班班表
        </el-button>
      </router-link> -->
    </FiltersContainer>

    <section v-loading="loading" class="flex-1">
      <FullCalendar v-if="show" ref="showCalendar" class="h-full block" :options="showCalendar" />
      <div style="height: 800px" />
    </section>

    <!-- 編輯排班 Dialog -->
    <el-dialog
      key="calendar-edit"
      title="編輯排班班表"
      :visible.sync="editDialog"
      width="700px"
      :close-on-click-modal="false"
      @close="
        ;(selectServiceUnit = null),
          (editCalendar.events = []),
          (editMode = false),
          (editNewSchedule = [])
      "
    >
      <el-form label-position="left" label-width="120px">
        <el-form-item label="選擇服務車次">
          <div class="flex">
            <el-select
              v-model="selectServiceUnit"
              placeholder="請選擇"
              value-key="id"
              no-data-text="無數據"
              @change="changeServiceUnit"
            >
              <el-option
                v-for="item in serviceUnitList"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
            <el-button
              v-if="!editMode && selectServiceUnit"
              plain
              class="ml-2 p-2"
              @click="editMode = true"
            >
              點我編輯
            </el-button>
            <!-- <el-button v-if="editMode">匯入上個月班表</el-button> -->
          </div>
        </el-form-item>
        <el-form-item v-if="editMode" label="選擇排班班別">
          <el-select
            v-model="editScheduleClass"
            placeholder="請選擇"
            value-key="id"
            no-data-text="無數據"
          >
            <el-option
              v-for="item in shopShiftsClassList"
              :key="item.name"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <section v-if="editMode" class="flex-end">
        <el-button
          plain
          @click="
            ;(editMode = false),
              changeServiceUnit(selectServiceUnit),
              (editNewSchedule = [])
          "
        >
          取 消
        </el-button>
        <el-button type="primary" @click="saveEditSchedule()">儲存</el-button>
      </section>

      <section v-if="editDialog" v-loading="loading" class="test">
        <FullCalendar ref="calendar" :options="editCalendar" />
      </section>
    </el-dialog>

    <!-- 月曆單一排班點擊 Dialog -->
    <el-dialog
      key="event-edit"
      title="編輯排班班別"
      :visible.sync="updateDialog"
      :close-on-click-modal="false"
    >
      <el-form label-position="top">
        <el-form-item label="日期 ：">
          <div v-if="selectSchedule">{{ selectSchedule.day }}</div>
        </el-form-item>
        <el-form-item label="服務車次 ：">
          <div v-if="selectSchedule">
            {{ selectSchedule.AppointmentUnit.name }}
          </div>
        </el-form-item>
        <el-form-item label="排班班別 ：">
          <el-select v-model="selectScheduleClass">
            <el-option
              v-for="item in shopShiftsClassList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="flex-end">
          <el-button plain @click="updateDialog = false">取消</el-button>
          <el-button
            type="primary"
            @click="updateScheduleCalss()"
          >
            儲存
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <TogglePeriodDialog
      v-if="togglePeriodDialog"
      title="編輯關閉時段"
      width="440px"
      @close="togglePeriodDialog = false"
    />
  </div>
</template>

<script>
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { GetServiceUnit } from '@/api/serviceUnit'
import {
  GetShiftsSchedule,
  GetShiftsScheduleCount,
  UpdateShiftsSchedule,
} from '@/api/shiftSchedule'
import { GetShiftsClass, GetShiftsClassCount } from '@/api/shiftsClass'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import { timesConvert } from '@/utils/helper'
import dayjs from 'dayjs'
import shiftCalendar from '@/mixin/shiftCalendar'

const loadDebounceKeyList = {}
function localDebounce (key, next) {
  const stl = 500
  if (loadDebounceKeyList[key]) {
    if (loadDebounceKeyList[key] + stl > +new Date()) {
      return () => {}
    }
  }
  loadDebounceKeyList[key] = +new Date()
  return next()
}

export default {
  name: 'ShiftsCalendarSetting',
  components: {
    FullCalendar,
    ServicesUnitSelect,
    TogglePeriodDialog,
  },
  mixins: [shiftCalendar],

  data () {
    return {
      show: true,
      loading: false,
      togglePeriodDialog: false,
      updateDialog: false, // 編輯月曆單一排班
      selectScheduleClass: null,

      editDialog: false, // 編輯所有班表
      editScheduleClass: null, // editDialog 中選擇班別
      editNewSchedule: [], // editDialog 班表編輯暫存
      editScheduleList: [],
      editMode: false, // editDialog 中是否啟用編輯狀態
      selectServiceUnit: null,

      shopShiftsClassList: [], // 店家班別
      shopScheduleList: [], // 店家排班表
      serviceUnitList: [], // 服務人員列表
      selectSchedule: null,

      editCalendar: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        buttonText: {
          today: '今日',
          month: '月檢視',
          week: '週檢視',
          day: '天檢視',
          list: '列表',
        },
        initialView: 'dayGridMonth',
        // 處理月曆 Toolbar 按鈕操控事件
        customButtons: {
          prev: {
            click: () => {
              this.$refs.calendar.getApi().prev()
              this.$refs.showCalendar.getApi().prev()
              this.changeCalendar()
            },
          },
          next: {
            click: () => {
              this.$refs.calendar.getApi().next()
              this.$refs.showCalendar.getApi().next()
              this.changeCalendar()
            },
          },
          today: {
            text: '今日',
            click: () => {
              this.$refs.calendar.getApi().today()
              this.$refs.showCalendar.getApi().today()
              this.changeCalendar()
            },
          },
        },
        weekends: true,
        longPressDelay: 100,

        selectable: true,
        displayEventTime: true,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        locale: 'en-gb',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
        },
        dateClick: this.editDateClick,
        eventClick: this.editEventClick,
        events: [],
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
      },
      // 傳給月曆的資料
      showCalendar: {
        longPressDelay: 100,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        buttonText: {
          month: '月檢視',
          week: '週檢視',
          day: '天檢視',
          list: '列表',
        },
        // 處理月曆 Toolbar 按鈕操控事件
        customButtons: {
          prev: {
            click: () => {
              this.$refs.showCalendar.getApi().prev()
              this.changeCalendar()
            },
          },
          next: {
            click: () => {
              this.$refs.showCalendar.getApi().next()
              this.changeCalendar()
            },
          },
          today: {
            text: '今日',
            click: () => {
              this.$refs.showCalendar.getApi().today()
              this.changeCalendar()
            },
          },
        },
        initialView: 'dayGridMonth',
        weekends: true,

        selectable: true,
        displayEventTime: true,
        displayEventEnd: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        locale: 'en-gb',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
        },
        // dateClick: this.showCalendarDateClick,
        eventClick: this.showCalendarEventClick,
        // 所有排班班表紀錄
        events: [],
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            // dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            dayMaxEventRows: 10, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
      },

      search: {
        unit: '',
      },
    }
  },

  computed: {
    ...mapGetters(['shop']),
    sidebar () { return this.$store.state.app.sidebar },
  },

  watch: {
    sidebar () {
      // const api = this.$refs.calendar.getApi()
      // api.render()
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
      console.log('resize')
    },
  },

  async mounted () {
    this.calendarRange = this.getCalendarDate(this.$refs.showCalendar.getApi())
    await this.getServiceUnit() //= > 取得分店人員
    await this.getShopShiftsClass() //= > 取得班別
    await this.getShiftsSchedule() //= > 取得排班表
    await this.showAllSchedule() //= > 顯示店家當前所有班表
  },

  methods: {
    // TODO 匯出
    // TODO 匯入上個月班表

    //= > 編輯月曆班表
    editDateClick: function (date) {
      localDebounce(date.dateStr, () => {
        if (!this.editMode) return
        if (!this.editScheduleClass) {
          this.$message.warning('請先選擇班別')
          return
        }
        const day = date.dateStr
        const serviceUnit = this.selectServiceUnit.id
        const scheduleClass = this.editScheduleClass.id
        const color = this.editScheduleClass.color
        const unitName = this.selectServiceUnit.name
        const times = this.editScheduleClass.AppointmentScheduleDayTimes

        // 當日已存在該班別則無視
        const isExist = this.editNewSchedule.find((item) => {
          if (day === item.day) {
            if (serviceUnit === item.appointmentUnitId) {
              if (scheduleClass === item.appointmentScheduleDayId) {
                return true
              }
            }
          }
        })

        this.editCalendar.events = this.editCalendar.events.filter((event) => {
          const start = event.start.split(' ')[0]
          return start !== day
        })
        this.editNewSchedule = this.editNewSchedule.filter(
          (item) => day !== item.day,
        )
        if (isExist) return

        // TODO 不可選上下月?

        // 增加暫存班表列表
        this.editNewSchedule.push({
          day,
          appointmentUnitId: serviceUnit,
          appointmentScheduleDayId: scheduleClass,
        })

        // 增加月曆事件
        times.forEach((item) => {
          this.createCalendarEvent({
            title: unitName,
            day,
            timeStart: item.start,
            timeEnd: item.end,
            mode: 'edit',
            props: {
              day,
              appointmentUnitId: serviceUnit,
              appointmentScheduleDayId: scheduleClass,
            },
            color,
          })
        })
      })
    },

    //= > 編輯模式 -> 移除排班
    editEventClick: function (e) {
      localDebounce(e.event.extendedProps.day, () => {
        if (this.editMode) {
          const date = e.event.extendedProps.day
          const scheduleClass = e.event.extendedProps.appointmentScheduleDayId

          const targetEvent = this.editNewSchedule.find((item) => {
            const itemDay = item.day
            const itemScheduleClass = item.appointmentScheduleDayId
            return date === itemDay && scheduleClass === itemScheduleClass
          })
          if (!targetEvent) return

          const events = this.editCalendar.events.filter((item) => {
            const itemDay = item.extendedProps.day
            const itemScheduleClass =
              item.extendedProps.appointmentScheduleDayId
            return date === itemDay && scheduleClass === itemScheduleClass
          })

          // 移除班別
          let index
          index = this.editNewSchedule.indexOf(targetEvent)
          const obj = this.editNewSchedule[index]
          delete obj.appointmentScheduleDayId
          // 移除event
          events.forEach((event) => {
            index = this.editCalendar.events.indexOf(event)
            this.editCalendar.events.splice(index, 1)
          })

          // if(targetEvent) {
          //   this.$confirm(`是否移除${date}的班別`, '提示', {
          //     confirmButtonText: '確定',
          //     cancelButtonText: '取消',
          //     type: 'warning'
          //   }).then(() => {

          //     this.$message({
          //       type: 'success',
          //       message: '删除成功!'
          //     })
          //   }).catch(()=> {})
          // }
        }

        // 非編輯模式 -> 關閉時段
        if (!this.editMode) {
          // TODO 關閉時段
        }
      })
    },

    //= > 儲存編輯班表與更新
    async saveEditSchedule () {
      await this.updateShiftsSchedule(this.editNewSchedule)
      await this.getShiftsSchedule()
      await this.changeServiceUnit(this.selectServiceUnit)
      await this.showAllSchedule()
    },

    //= > 更新選取的班別
    async updateScheduleCalss () {
      const id = this.selectSchedule.id
      const newScheduleClass = this.selectScheduleClass
      const newSchedule = []

      this.shopScheduleList.forEach((item) => {
        if (item.id === id) {
          item.AppointmentScheduleDayId = newScheduleClass
        }

        newSchedule.push({
          day: item.day,
          appointmentUnitId: item.AppointmentUnitId,
          appointmentScheduleDayId: item.AppointmentScheduleDayId,
        })
      })

      try {
        this.loading = true
        await this.updateShiftsSchedule(newSchedule)
        await this.getShiftsSchedule()
        await this.showAllSchedule()
        this.loading = false
        this.updateDialog = false
      } catch (error) {
        console.log(error)
      }
    },

    showCalendarEventClick (data) {
      this.selectScheduleClass =
        data.event.extendedProps.AppointmentScheduleDayId
      this.selectSchedule = data.event.extendedProps
      this.updateDialog = true
    },

    // => 更新班表
    async updateShiftsSchedule (newSchedule) {
      try {
        await UpdateShiftsSchedule({
          shopId: this.shop,
          data: newSchedule,
        })
        this.$message.success('更新排班班表成功 !')
      } catch (error) {
        console.log(error)
      }
    },

    // => 變換店家
    async changeShop () {
      await this.getServiceUnit()
      await this.getShopShiftsClass()
      await this.getShiftsSchedule()
      await this.showAllSchedule()
    },

    // => 顯示店家當前所有班表
    async showAllSchedule () {
      this.showCalendar.events = []
      let schedules = JSON.parse(JSON.stringify(this.shopScheduleList))
      schedules = schedules.filter((item) => !item.AppointmentUnit.isRemove)
      if (this.search.unit !== '') {
        schedules = schedules.filter((item) => {
          return item.AppointmentUnitId === this.search.unit.id
        })
      }
      schedules.forEach(async (item) => {
        // 取得班表的班別
        const shiftsClass = this.shopShiftsClassList.find(
          (shiftsClass) => shiftsClass.id === item.AppointmentScheduleDayId,
        )
        if (!shiftsClass) return
        const times = shiftsClass.AppointmentScheduleDayTimes
        const color = item.AppointmentScheduleDay.color

        times.forEach((time) => {
          this.createCalendarEvent({
            title: item.AppointmentUnit.name,
            day: item.day,
            timeStart: time.start,
            timeEnd: time.end,
            mode: 'all',
            props: item,
            color,
          })
        })
      })
    },

    // => 選擇服務人員 > 顯示班表
    async changeServiceUnit (v) {
      this.editCalendar.events = []
      this.editNewSchedule = []
      this.loading = true
      const schedule = this.shopScheduleList.filter(
        (item) => item.AppointmentUnit.id === v.id,
      )

      schedule.forEach(async (item) => {
        const shiftsClass = this.shopShiftsClassList.find(
          (shiftsClass) => shiftsClass.id === item.AppointmentScheduleDayId,
        )
        if (!shiftsClass) return
        const times = shiftsClass.AppointmentScheduleDayTimes
        const color = item.AppointmentScheduleDay.color

        this.editNewSchedule.push({
          day: item.day,
          appointmentUnitId: item.AppointmentUnit.id,
          appointmentScheduleDayId: item.AppointmentScheduleDayId,
        })

        times.forEach((time) => {
          this.createCalendarEvent({
            title: item.AppointmentUnit.name,
            day: item.day,
            timeStart: time.start,
            timeEnd: time.end,
            mode: 'edit',
            props: {
              day: item.day,
              appointmentUnitId: item.AppointmentUnit.id,
              appointmentScheduleDayId: item.AppointmentScheduleDayId,
            },
            color,
          })
        })
      })
      this.loading = false
    },

    //= > 創建月曆事件
    createCalendarEvent ({
      title,
      day,
      timeStart,
      timeEnd,
      mode,
      props,
      color,
    }) {
      const start = timesConvert(timeStart)
      let end = timesConvert(timeEnd)
      if (end === '24:00') end = '23:59:59'
      const data = {
        title,
        start: `${day} ${start}`,
        end: `${day} ${end}`,
        allDay: false,
        extendedProps: props,
        backgroundColor: color,
      }

      if (mode === 'edit') this.editCalendar.events.push(data)
      if (mode === 'all') this.showCalendar.events.push(data)
    },

    //= > 取得服務人員
    async getServiceUnit () {
      this.loading = true
      try {
        const startIndex = 0
        const limit = 20
        const res = await GetServiceUnit({
          shopId: this.shop,
          start: startIndex,
          limit,
        })
        this.serviceUnitList = res
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error('取得服務車次失敗')
      }
    },

    //= > 切換月曆(前後)
    async changeCalendar () {
      const prevDayStart = this.calendarRangeString.dayStart
      this.calendarRange = this.getCalendarDate(this.$refs.showCalendar.getApi())
      const currentDayStart = this.calendarRangeString.dayStart
      if (prevDayStart === currentDayStart) return
      await this.getShiftsSchedule()
      if (this.selectServiceUnit) { await this.changeServiceUnit(this.selectServiceUnit) }
      this.showAllSchedule()
    },

    //= > 點選編輯班表
    handleEditDialog () {
      this.editDialog = true
      this.$nextTick(() => {
        this.$refs.calendar
          .getApi()
          .gotoDate(this.$refs.showCalendar.getApi().getDate())
      })
    },
  },
}
</script>

<style>

/* .fc-daygrid-day-frame {
  overflow: auto;
} */
/* .fc .fc-daygrid-day {
  width: 100%;
}
.fc .fc-daygrid-day-frame {
  overflow: auto;
  flex: 2;
  box-sizing: border-box;
}

.fc-daygrid-day-top {
  justify-content: flex-end;
}
.fc-highlight {
  width: 200%;
}
.fc-daygrid-event {
  flex-wrap: wrap;
  overflow: auto;
  width: 200%;
} */
</style>

<style scoped lang="postcss">
::v-deep .fc .fc-scroller-liquid-absolute {
  display: block;
  position:  unset !important;
  overflow: hidden !important;
  /* height: 100%; */
}

.shifts-calendar-setting {
  @apply pb-[400px] mb-[400px] h-full flex flex-col;
}
header {
  margin-right: 3rem;
}

section {
  flex: 1;
}
</style>
